<template>
  <div>
    <div class="top">
      <el-date-picker
        :value="post.time"
        @input="changeTime1(arguments[0])"
        type="daterange"
        align="right"
        unlink-panels
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :pickerOptions="pickerOptions"
      ></el-date-picker>
      <selectTimeInterval
        class="selectTim"
        v-model="post.area_type"
        @input="input"
        :option="[
          { name: '最近7天', id: 1 },
          { name: '最近30天', id: 2 }
        ]"
      ></selectTimeInterval>
    </div>
    <div class="contain1">
      <div class="flex-center title">
        流量概况
        <helpIcon
          class="help-icon"
          content="统计网校累计的访问人数和访问人次，包括网校和课程访问数据；<br />
课程访客：统计学员进入课程详情、直播间、分享课程页的数据<br />
网校访客：统计学员进入网校首页、我的课程、个人中心、课程详情、直播间、分享课程页面的数据<br />
访问人数：统计访问人数总数量，同一个用户重复访问单日去重，多日累加 <br />
访问人次：统计访问人次总数量，同一个用户重复访问算多人次"
        ></helpIcon>
        <!-- <div class="select-time">
          <selectTimeInterval v-model="area_type_1"></selectTimeInterval>
        </div>-->
      </div>
      <div class="flex-center top-contain">
        <div class="item">
          <div class="title2">
            网校访客数(UV)
            <i>{{ uvtotal.visit_user_num }}</i>
            人
          </div>
          <div class="pieChart">
            <pieChart :pieData="list1"></pieChart>
          </div>
        </div>
        <div class="item">
          <div class="title2">
            网校访问次数(PV)
            <i>{{ pvtotal.visit_num }}</i>
            次
          </div>
          <div class="pieChart">
            <pieChart :pieData="list2"></pieChart>
          </div>
        </div>
      </div>
    </div>
    <!--流量增长趋势-->
    <div class="mt20 studentGrowthChart">
      <div class="flex-center header">
        <div class="title">流量增长趋势</div>
      </div>
      <div class="line-chart">
        <lineChart :options="list3"></lineChart>
      </div>
    </div>

    <!--每日访问数据-->
    <div class="contain3">
      <div class="flex-center tab-header">
        <span class="title">访问记录</span>
      </div>
      <el-table
        :data="analysisIncrement2"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333'
        }"
      >
        <el-table-column label="日期" prop="0">
          <template slot-scope="{ row }">
            <span>{{ row['0'] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="网校访客数(UV)" prop="1">
          <template slot-scope="{ row }">
            <span class="cp" @click="jump(row)">{{ row['1'] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="网校访问次数(PV)" prop="3">
          <template slot-scope="{ row }">
            <span class="cp" @click="jump(row)">{{ row['3'] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程访客数" prop="2">
          <template slot-scope="{ row }">
            <span class="cp" @click="jump(row)">{{ row['2'] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程访问次数" prop="4">
          <template slot-scope="{ row }">
            <span class="cp" @click="jump(row)">{{ row['4'] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="post2.num = arguments[0]"
          @current-change="post2.page = arguments[0]"
          :current-page="post2.page"
          :page-sizes="$store.state.pageSize"
          :page-size="post2.num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="analysisIncrement.length"
        ></el-pagination>
      </div>
    </div>
    <!--访问终端系统占比 访问渠道占比-->
    <div class="flex-center mt20 function-views">
      <div class="pie">
        <div class="title flex-center mb20">访问终端系统占比</div>
        <div class="pie-contain">
          <pieChart :options="pieOption1" :pieData="list4"></pieChart>
        </div>
      </div>
      <div class="pie">
        <div class="title flex-center mb20">访问渠道占比</div>
        <div class="pie-contain">
          <pieChart :options="pieOption1" :pieData="list5"></pieChart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
import pieChart from '@/components/dataAnalysis/pieChart'
import lineChart from '@/components/dataAnalysis/line'
import selectTimeInterval from './selectTimeInterval'

export default {
  name: 'flowAnalysis',

  components: {
    helpIcon,
    pieChart,
    lineChart,
    selectTimeInterval
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 86400000
        }
      },

      pieOption1: {
        legend: {
          bottom: 'auto',
          left: 0,
          top: 0
        },
        series: [
          {
            top: 34,
            bottom: 0
          }
        ]
      },

      // 网校访客数
      list1: [],
      uvtotal: {
        visit_user_num: 0,
        course_visit_user_num: 0
      },

      // 网校访问次数
      list2: [],
      pvtotal: {
        visit_num: 0,
        course_visit_num: 0
      },

      // 流量增长趋势
      list3: {},

      // 访问终端系统占比
      list4: [],

      // 访问渠道占比
      list5: [],

      analysisIncrement: [],

      // 流量增长趋势
      post: {
        startTime: '',
        endTime: '',
        time: '',
        area_type: 1
      },

      // 流量增长趋势
      post2: {
        page: 1,
        num: this.$store.state.pageSize[0]
      }
    }
  },

  computed: {
    analysisIncrement2() {
      const index = (this.post2.page - 1) * this.post2.num
      return this.analysisIncrement.slice(index, index + this.post2.num)
    }
  },

  watch: {
    post: {
      handler() {
        this.post2.page = 1
        // 获取学员增长数据
        this.incrementData()
        // 基础数据API
        this.getData()
      },
      deep: true,
      immediate: true
    }
  },
  mounted(){
    console.log('this.$route.query',this.$route.query);
    this.post.time = [this.$route.query.startTime,this.$route.query.endTime]
  },
  methods: {
    //监听 最近七天的点击事件
    input() {
      this.post.startTime = ''
      this.post.endTime = ''
      this.post.time = ''
    },

    // 基础数据API   // 访问终端系统 访问渠道占比
    getData() {
      this.$http({
        url: '/statistics/analysisTotal',
        data: this.post,
        callback: ({ data: { uvtotal, pvtotal, user_device, user_from } }) => {
          this.uvtotal = uvtotal
          this.pvtotal = pvtotal

          // 网校访客数
          this.list1 = [
            {
              value: uvtotal.visit_user_num,
              name: '网校访客数',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: uvtotal.course_visit_user_num,
              name: '课程访客数',
              itemStyle: { color: '#FED74C' }
            }
          ]

          // 网校访问次数
          this.list2 = [
            {
              value: pvtotal.visit_num,
              name: '网校访问次数',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: pvtotal.course_visit_num,
              name: '课程访问次数',
              itemStyle: { color: '#FED74C' }
            }
          ]
          // 网校访客数
          this.list4 = [
            {
              value: user_device.ios_num,
              name: 'iOS',
              itemStyle: { color: '#FED74C' }
            },
            {
              value: user_device.android_num,
              name: 'Android',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: user_device.other_num,
              name: '其他',
              itemStyle: { color: '#00D966' }
            }
          ]
          // 网校访问次数
          this.list5 = [
            {
              value: user_from.h5_num,
              name: '微信H5',
              itemStyle: { color: '#FED74C' }
            },
            {
              value: user_from.xcx_num,
              name: '小程序',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: user_from.pc_num,
              name: '电脑',
              itemStyle: { color: '#00D966' }
            }
          ]
        }
      })
    },

    // 流量增长趋势
    incrementData() {
      this.$http({
        url: '/statistics/analysisIncrement',
        data: this.post,
        callback: ({ data: { analysisIncrement } }) => {
          this.analysisIncrement = [].concat(analysisIncrement).reverse()
          this.list3 = {
            legend: {
              left: 0,
              data: [
                '网校访客数(UV)',
                '网校访问次数(PV)',
                '课程访客数',
                '课程访问次数'
              ]
            },
            xAxis: {
              data: analysisIncrement.map(item => item[0])
            },
            yAxis: {
              minInterval: null
            },
            series: [
              {
                name: '网校访客数(UV)',
                type: 'line',
                data: analysisIncrement.map(item => item[1]),
                lineStyle: {
                  color: '#00D966'
                },
                itemStyle: {
                  color: '#00D966',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '网校访问次数(PV)',
                type: 'line',
                data: analysisIncrement.map(item => item[3]),
                lineStyle: {
                  color: '#0098FF'
                },
                itemStyle: {
                  color: '#0098FF',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '课程访客数',
                type: 'line',
                data: analysisIncrement.map(item => item[2]),
                lineStyle: {
                  color: '#F8B832'
                },
                itemStyle: {
                  color: '#F8B832',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '课程访问次数',
                type: 'line',
                data: analysisIncrement.map(item => item[4]),
                lineStyle: {
                  color: '#B675F0'
                },
                itemStyle: {
                  color: '#B675F0',
                  borderWidth: 4
                },
                smooth: true
              }
            ]
          }
        }
      })
    },

    // 选择日期
    changeTime1(value, name = 'post') {
      this[name] = Object.assign({}, this[name], {
        startTime: value ? value[0] / 1000 : '',
        endTime: value ? value[1] / 1000 : '',
        time: value || '',
        area_type: value ? 3 : 1
      })
    },
    jump(row){
      const startTime = new Date(row['0']).getTime()/1000
      const endTime = new Date(row['0']).getTime()/1000
      this.$router.push({
        path:'/statisticalAnalysis/records',
        query:{
          startTime,
          endTime,
        },
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.top {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  .char-menu2 {
    width: 158px;
    margin-right: 20px;
  }
}
.contain1 {
  padding: 20px;
  margin-top: 10px;
  white-space: nowrap;
  background: #fff;
  .help-icon {
    flex: 1;
    margin-left: 6px;
  }
  .title {
    margin-bottom: 40px;
  }
}
.top-contain {
  .item {
    flex: 1;
    .title2 {
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      margin-bottom: 20px;
      text-align: center;
      color: rgba(51, 51, 51, 1);
      i {
        font-size: 24px;
        font-weight: bold;
        line-height: 31px;
        margin: 0 4px 0 14px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
}
.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
}
.pieChart {
  font-size: 0;
  height: 246px;
  position: relative;
}
.select-time {
  width: 316px;
  height: 30px;
  margin: 0 auto;
}
.studentGrowthChart {
  padding: 20px;
  background: #fff;
  .title {
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }
  .pie-contain {
    height: 228px;
    position: relative;
  }
  .char-menu {
    width: 316px;
    height: 30px;
    margin: 20px auto 0;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(229, 229, 229, 1);
  }
}
.studentGrowthChart {
  padding-bottom: 30px;
  .header {
    margin-bottom: 12px;
    .selectTim {
      width: 158px;
      margin-right: 20px;
    }
    ::v-deep .el-input,
    ::v-deep .el-range-input {
      font-size: 12px;
      line-height: 30px;
    }
    ::v-deep .el-range-separator,
    ::v-deep .el-date-editor .el-range__icon,
    ::v-deep .el-date-editor .el-range__close-icon {
      line-height: 25px;
    }
    ::v-deep .el-form-item__content {
      height: 30px;
    }
    ::v-deep .el-input__inner,
    ::v-deep .el-input {
      width: 120px;
    }
    ::v-deep .el-date-editor {
      width: 220px;
      height: 30px;
    }
    ::v-deep .el-form-item {
      margin: 0;
    }
  }
}
.line-chart {
  height: 305px;
  position: relative;
}
.function-views {
  flex-wrap: wrap;
  > div:nth-child(2n) {
    margin-left: 20px;
  }
}
.pie {
  flex: 1;
  height: 328px;
  padding: 20px;
  display: flex;
  flex-flow: column;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  .pie-contain {
    flex: 1;
    margin-bottom: 20px;
    position: relative;
  }
  .bottom-price {
    .item {
      width: 60%;
      font-size: 0;
      margin: 0 auto;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .text {
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        color: #666;
      }
    }
  }
}
.contain2,
.contain3 {
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}
.course-info {
  .cover {
    width: 68px;
    height: 38px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .info {
    flex: 1;
    overflow: hidden;
    margin-left: 10px;
    .name {
      overflow: hidden;
      font-size: 14px;
      line-height: 19px;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: rgba(51, 51, 51, 1);
    }
    .price {
      font-size: 12px;
      margin-top: 6px;
      line-height: 16px;
      color: rgba(255, 53, 53, 1);
    }
  }
}
.tab-header {
  margin-bottom: 30px;
  .tag {
    width: 2px;
    height: 20px;
    background: rgba(27, 157, 151, 1);
  }
  .title {
    flex: 1;
  }
  ::v-deep .el-input,
  ::v-deep .el-range-input {
    font-size: 12px;
    line-height: 36px;
  }
  ::v-deep .el-range-separator,
  ::v-deep .el-date-editor .el-range__icon,
  ::v-deep .el-date-editor .el-range__close-icon {
    line-height: 33px;
  }
  ::v-deep .el-form-item__content {
    height: 36px;
  }
  ::v-deep .el-input__inner,
  ::v-deep .el-input {
    width: 120px;
  }
  // ::v-deep .el-date-editor {
  //   width: 220px;
  // }
  ::v-deep .el-form-item {
    margin: 0;
  }
}
::v-deep .el_tab {
  margin-bottom: 25px;
  .el-tabs__header {
    border: 0;
  }
  .el-tabs__nav {
    border-bottom: 1px solid #e4e7ed;
  }
}
.pagination {
  padding: 50px 0 30px;
  text-align: center;
}
::v-deep .el-pager li {
  background: #f4f4f5;
  margin: 0 4px;
  padding: 0;
  font-size: 12px;
}
::v-deep .el-pagination button {
  background: #f4f4f5 !important;
}
</style>
